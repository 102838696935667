import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
  faCalendarCheck,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "./styles.css";
import heroImage from "./assets/hero-image.webp";
import aboutImage from "./assets/about-image.webp";
import logo from "./assets/logo.webp";
import wellnessImage from "./assets/wellnessImage.webp";
import camarah from "./assets/cmarah.webp";
import serviceImage1 from "./assets/oxigeno.webp";
import serviceImage2 from "./assets/deporte.webp";
import serviceImage3 from "./assets/heridas.webp";
import modalImage from "./assets/modal-image.webp"; // Usa la imagen que subiste

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [showModal, setShowModal] = useState(true); // Estado para mostrar el modal

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="app">
      {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={closeModal}>
              ✖
            </button>
            <div className="modal-image-wrapper">
              <img src={modalImage} alt="Promoción" className="modal-image smaller" />
            </div>
          </div>
        </div>
      )}
      <header className="header">
        <nav className="navbar">
          <div className="navbar-left">
            <img src={logo} alt="Cámaras Hiperbáricas" className="logo" />
          </div>
          <div className="navbar-right">
            <ul className="nav-links-desktop">
              <li>
                <a href="#home">Inicio</a>
              </li>
              <li>
                <a href="#about">Sobre Nosotros</a>
              </li>
              <li>
                <a href="#services">Servicios</a>
              </li>
              <li>
                <a href="#contact">Contacto</a>
              </li>
              <li>
                <a
                  href="https://www.doctoralia.com.mx/clinicas/jose-cortes-institute-2"
                  className="cta-button cta-button-appointment"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faCalendarCheck} />
                  Agendar cita
                </a>
              </li>
            </ul>
            <div className="hamburger" onClick={toggleMenu}>
              {isMenuOpen ? (
                <div className="close">✖</div>
              ) : (
                <>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </>
              )}
            </div>
          </div>
          <ul className={`nav-links-mobile ${isMenuOpen ? "open" : ""}`}>
            <li><img src={logo} alt="Cámaras Hiperbáricas" className="logo" /></li>
            <li>
              <a href="#home" onClick={() => setIsMenuOpen(false)}>
                Inicio
              </a>
            </li>
            <li>
              <a href="#about" onClick={() => setIsMenuOpen(false)}>
                Sobre Nosotros
              </a>
            </li>
            <li>
              <a href="#services" onClick={() => setIsMenuOpen(false)}>
                Servicios
              </a>
            </li>
            <li>
              <a href="#contact" onClick={() => setIsMenuOpen(false)}>
                Contacto
              </a>
            </li>
            <li>
              <a
                href="https://www.doctoralia.com.mx/clinicas/jose-cortes-institute-2"
                className="cta-button cta-button-secondary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Agendar cita
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <section id="home" className="hero-section">
        <img src={heroImage} alt="Cámara Hiperbárica" className="hero-image" />
        <div className="hero-text">
          <h2>Mejora tu salud con la terapia hiperbárica</h2>
          <p>
            Ofrecemos tratamientos seguros y efectivos para mejorar la salud y
            el bienestar.
          </p>
          <a
            href="https://www.doctoralia.com.mx/clinicas/jose-cortes-institute-2"
            className="cta-button cta-button-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contáctanos
          </a>
        </div>
      </section>
      <section id="recuperacion" className="recuperacion-section">
        <div className="recuperacion-container">
          <div className="recuperacion-text">
            <h2>Cámara hiperbárica en México - Dr. José Cortes Institute</h2>
            <p>
              Redescubre tu bienestar y eleva tu calidad de vida con la
              tecnología más avanzada en medicina hiperbárica que ofrecemos en
              el Dr. José Cortes Institute en México. Nuestras terapias de
              oxígeno puro al 100% están diseñadas para acelerar la recuperación
              postquirúrgica, rejuvenecer la piel, y revitalizar tu energía.
              Respira profundamente, renueva tu cuerpo y mente, y permite que
              nuestro equipo de especialistas te guíe en el camino hacia una
              salud óptima.
            </p>
            <div className="recuperacion-buttons">
              <a href="#services" className="cta-button cta-button-primary">
                Servicios
              </a>
              <button className="cta-button cta-button-secondary">
                Paquetes
              </button>
            </div>
          </div>
          <img
            src={camarah}
            alt="Cámara hiperbárica en México - Dr. José Cortes Institute"
            className="recuperacion-image responsive-image"
          />
        </div>
      </section>
      <section id="about" className="about-section">
        <div className="about-container">
          <div className="about-content about-content-enhanced">
            <h2>Sobre Nosotros</h2>
            <p>
              En José Cortes Institute, somos el centro de medicina hiperbárica
              de confianza que estás buscando.
            </p>
            <p>
              Contamos con modernas cámaras hiperbáricas en México, certificadas
              para ofrecer oxígeno medicinal puro en un ambiente seguro. Nuestro
              equipo está formado por especialistas altamente capacitados en
              medicina hiperbárica, comprometidos en brindarte una atención de
              calidad que favorezca tu bienestar y salud.
            </p>
            <p>
              Aquí encontrarás tecnología avanzada, estándares rigurosos en
              equipamiento médico, y un trato cercano y personalizado para
              asegurar una experiencia de recuperación óptima para cada
              paciente.
            </p>
            <p>
              Ofrecemos sesiones de terapia de oxígeno al 100%, seguras y
              respaldadas por evidencia científica, que complementan los
              tratamientos convencionales. Estas terapias están diseñadas para
              acelerar la recuperación, promover la cicatrización de heridas,
              reducir el dolor y mejorar la calidad de vida de quienes nos
              eligen.
            </p>
            <p>
              Comunícate con nosotros a través de nuestro formulario de contacto
              para resolver tus dudas y obtener toda la información que
              necesites sobre la terapia de oxigenación hiperbárica.
            </p>
          </div>
          <iframe
            className="about-image about-image-right"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/24IZYIQxsBk?si=VdXMSvrdQoaOFzVc&amp;controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section id="wellness" className="wellness-section">
        <div className="wellness-container">
          <div className="wellness-text">
            <h2>
              Renueva tu energía y<br /> transforma tu bienestar
            </h2>
            <p>
              Sumérgete en una experiencia única diseñada para ti. Nuestras
              terapias personalizadas combinan comodidad y tecnología de
              vanguardia en un entorno pensado para tu relajación y
              recuperación. Disfruta de sesiones que revitalizan tu cuerpo,
              despejan tu mente y renuevan tu espíritu. Aquí, cada respiro es un
              paso hacia una mejor versión de ti. ¡Bienvenido a un espacio donde
              tu salud y bienestar son nuestra prioridad!
            </p>
            <br />
            <a
              href="https://www.doctoralia.com.mx/clinicas/jose-cortes-institute-2"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button cta-button-whatsapp"
            >
              Agendar sesión
            </a>
          </div>
          <img
            src={wellnessImage}
            alt="Sesión de Bienestar"
            className="wellness-image responsive-image"
          />
        </div>
      </section>
      <section id="services" className="services-section">
        <h2>Nuestros Servicios</h2>
        <p>
          Descubre nuestra gama de sesiones de terapia de oxigenación
          hiperbárica, diseñadas para atender tus necesidades de salud,
          bienestar y rendimiento. Desde tratamientos médicos y estéticos hasta
          recuperación quirúrgica y mejora deportiva, cada paquete está pensado
          para ayudarte a alcanzar un nuevo nivel de vitalidad. Escoge la opción
          que mejor se adapte a ti y potencia tu bienestar físico y mental.
        </p>
        <div className="services">
          <div className="service">
            <img
              src={serviceImage1}
              alt="Terapia de Oxígeno Hiperbárico"
              className="service-image"
            />
            <h3>Terapia de Oxígeno Hiperbárico</h3>
            <p>
              Tratamientos para una variedad de condiciones médicas, ayudando en
              la recuperación y mejorando la salud.
            </p>
          </div>
          <div className="service">
            <img
              src={serviceImage2}
              alt="Mejora del Rendimiento Deportivo"
              className="service-image"
            />
            <h3>Mejora del Rendimiento Deportivo</h3>
            <p>
              Ayudamos a los atletas a optimizar su rendimiento y acelerar la
              recuperación después de la actividad física.
            </p>
          </div>
          <div className="service">
            <img
              src={serviceImage3}
              alt="Tratamiento de Heridas Crónicas"
              className="service-image"
            />
            <h3>Tratamiento de Heridas Crónicas</h3>
            <p>
              Nuestros tratamientos están diseñados para acelerar la curación de
              heridas crónicas y difíciles de sanar.
            </p>
          </div>
        </div>
      </section>
      <section id="centro-medicina" className="centro-medicina-section">
        <div className="centro-medicina-container reverse-layout">
          <img
            src={aboutImage}
            alt="Centro Medicina Hiperbárica"
            className="centro-medicina-image responsive-image"
          />
          <div className="centro-medicina-text">
            <h2>Centro Medicina Hiperbárica</h2>
            <p>
              Nuestras cámaras hiperbáricas son las mejores del mercado, cuentan
              con la última tecnología y las mayores capacidades técnicas, en un
              ambiente totalmente controlado.
            </p>
            <br />
            <a
              href="https://www.doctoralia.com.mx/clinicas/jose-cortes-institute-2"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button cta-button-whatsapp"
            >
              Agendar sesión
            </a>
          </div>
        </div>
      </section>
      <section id="contact" className="contact-section">
        <h2>¿Tienes alguna duda?</h2>
        <p>En breve te contactaremos.</p>
        <div className="contact-container">
          <div className="contact-info">
            <div className="contact-detail">
              <FontAwesomeIcon icon={faLocationDot} />
              <p>
                Av. Ejército Nacional Mexicano 453–Piso 15,
                <br />
                Chapultepec Morales, Granada, Miguel Hidalgo, 11520
                <br />
                Ciudad de México, CDMX
              </p>
            </div>
            <div className="contact-detail">
              <FontAwesomeIcon icon={faPhone} />
              <p>
                Llámanos al <a href="tel:+5593062145">+55 9306 2145</a>
                <br />Ó escríbenos por Whatsapp al{" "}
                <a href="https://wa.me/5548134090">+55 4813 4090</a>
              </p>
            </div>
            <div className="contact-detail">
              <FontAwesomeIcon icon={faEnvelope} />
              <p>
                Agenda una cita a través de{" "}
                <a href="https://www.doctoralia.com.mx/clinicas/jose-cortes-institute-2">
                  Doctoralia
                </a>
                <br />
                Puedes elegir la fecha y hora de la cita que más te convenga.
              </p>
            </div>
          </div>
          <form className="contact-form">
            <input type="text" placeholder="Nombre" required />
            <input type="email" placeholder="Correo" required />
            <input type="tel" placeholder="Teléfono" required />
            <select disabled required>
              <option value="consulta">Cámara Hiperbárica</option>
            </select>
            <textarea placeholder="Mensaje" required></textarea>
            <button type="submit" className="cta-button cta-button-primary">
              Enviar
            </button>
          </form>
        </div>
      </section>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.3905563004682!2d-99.19119242325148!3d19.438721040482296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff6af4200489%3A0xd44d3646ffeb3b2!2sJos%C3%A9%20Cort%C3%A9s%20Institute!5e0!3m2!1ses-419!2smx!4v1730847922400!5m2!1ses-419!2smx"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Ubicación de José Cortés Institute en Google Maps" // Agrega un título descriptivo
        ></iframe>
      </div>
      <section className="footer-info">
        <div className="footer-info-container">
          <div className="footer-logo">
            <img
              src={logo}
              alt="José Cortés Institute Logo"
              className="footer-logo-image"
            />
            <p>J.C. SIEMPRE BELLEZA NATURAL S.A. DE C.V.</p>
            <p>
              ¡También puedes agendar una cita con el doctor vía Doctoralia!
            </p>
            <a
              href="https://www.doctoralia.com.mx/clinicas/jose-cortes-institute-2"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button cta-button-schedule"
            >
              <FontAwesomeIcon icon={faCalendarCheck} /> Agendar ahora
            </a>
          </div>

          <div className="footer-contact">
            <h3>Contáctanos:</h3>
            <p>
              <a href="https://www.google.com/maps/place/Cirugia+Pl%C3%A1stica+Dr.+Jos%C3%A9+Cort%C3%A9s/@19.4061584,-99.170288,15z/data=!4m5!3m4!1s0x0:0xd44d3646ffeb3b2!8m2!3d19.4061595!4d-99.1702858">
                <FontAwesomeIcon icon={faLocationDot} className="fa-icon" /> Av.
                Ejército Nacional Mexicano 453–Piso 15,
                <br />
                Chapultepec Morales, Granada, Miguel Hidalgo, 11520 Ciudad de
                México, CDMX
              </a>
            </p>
            <p>
              <a href="tel:+5593062145">
                <FontAwesomeIcon icon={faPhone} className="fa-icon" /> Teléfono:
                +55 9306 2145
              </a>
            </p>
            <p>
              <div
                className="whatsapp-button2"
                onClick={() =>
                  window.open(
                    "https://web.whatsapp.com/send?phone=525548134090&text=Hola, te estoy escribiendo desde la página del Dr. José Cortés de cámaras hiperbáricas",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                <FontAwesomeIcon icon={faWhatsapp} className="fa-icon" />{" "}
                WhatsApp: +55 4813 4090
              </div>
            </p>
          </div>

          <div className="footer-social">
            <h3>Redes sociales:</h3>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/JoseCortesinstitute?mibextid=PtKPJ9"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://www.instagram.com/josecortes.institute/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://youtube.com/channel/UCTDnLYSxu7jfuJ2MWyIgJkw?si=rqr3kyyy00paPb3-"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a
                href="https://www.doctoralia.com.mx/clinicas/jose-cortes-institute-2"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faLink} />
              </a>
            </div>
          </div>
        </div>
      </section>
      {showScrollToTop && (
        <button className="scroll-to-top" onClick={scrollToTop}>
          ↑
        </button>
      )}
      {/* <div
        className="whatsapp-button"
        onClick={() =>
          window.open(
            "https://web.whatsapp.com/send?phone=525548134090&text=Hola, te estoy escribiendo desde la página del Dr. José Cortés de cámaras hiperbáricas",
            "_blank",
            "noreferrer"
          )
        }
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </div> */}
      <a
        className="whatsapp-button"
        href="https://wa.me/525548134090?text=Hola,%20te%20estoy%20escribiendo%20desde%20la%20página%20del%20Dr.%20José%20Cortés%20de%20cámaras%20hiperbáricas"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <footer className="footer">
        <p>
          &copy; AVISO PUBLICIDAD COFEPRIS: 193300201A0106 |{" "}
          <a href="https://josecortes.com/privacidad/">AVÍSO DE PRIVACIDAD</a>
        </p>
      </footer>
    </div>
  );
}

export default App;
